
.main{
  display: grid;
  grid-template-rows: repeat(10,10%);
  grid-template-columns: repeat(20,5%);
  }
  .mainCard{
    margin-top: 2rem;
    grid-row: 2/11;
    grid-column: 2/20;
    height: 80vh;
    width: 100%;
    padding: 0.4rem 1rem;
    display: flex;
    flex-direction: column;
    background:linear-gradient(120deg,rgb(230, 239, 240)  , #5D5C31 80% );
    gap:2rem;
    border-radius: 0.75rem;
  }
  /* font-family: var(--font-mono); */
 .header{
  grid-row: 1;
  width: 100%;
  height: 5rem;
  padding: 1rem 4rem;
  background-color: rgb(0, 0, 0) !important;
 }
  .diagonal-line {
    width: 200vw;
    height: 2px; /* Adjust the thickness of the line as needed */
    background-color: #000; 
    transform: rotate(40deg);
    transform-origin: top left;
    /* Adjust the color of the line as needed */
  }

  .journey{
    color: #1c012ebc;
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    
}
.body{
    display: grid;
    grid-template-columns: repeat(auto-fit, 350px);
    padding: 4rem 5rem; 
    row-gap:2rem;
    column-gap: 1rem;
    grid-auto-flow: dense;
    overflow-y: scroll;
}
.body:hover{
  overflow-y: scroll;
}
.common {
    width:300px;
    height: 250px;
    opacity: 0.78;
    background:linear-gradient(120deg,rgb(128, 128, 4)  , rgb(176, 207, 161) 80% );
   border-radius: 0.5rem;
   border: solid 1px rgb(77, 76, 76) !important;
   box-shadow: 5px 2px ;
  cursor: pointer;
  text-decoration: none !important;
  color: inherit;
}

.grovify-img{
  
  grid-column: 1 / 3;
  grid-row: 1/3;
  width: 150px;
height: 82.54px;
border: solid 1px rgb(127, 124, 124);
border-radius: 0.5rem;

}
.grovify-img1{
  grid-column: 3 / 5;
  grid-row: 1/4;
  width: 150px;
  height: 123.81px;
  border: solid 1px rgb(127, 124, 124);
  border-radius: 0.5rem;
}
.grovify-img2{
  grid-column: 1 / 3;
  grid-row: 3/5;
  width: 75px;
  height: 105px;
 border: solid 1px rgb(127, 124, 124);
 border-radius: 0.5rem;
}
.grovify-img4{
  height: 62.5px;
  width: 225px;
  grid-column: 2/5;
  grid-row: 4;
  border: solid 1px rgb(127, 124, 124);
  border-radius: 0.5rem;
  
}
.grovify-img5{
  height: 25px;
  width: 300px;
  margin-top: 20px;
  grid-column: 1/5;
  grid-row: 5;
  border: solid 1px rgb(127, 124, 124);
  border-radius: 0.5rem;
}
.grovify-img6{
  height: 41.27px;
  width: 75px;
  grid-column: 2/3;
  grid-row: 3/4;
  border: solid 1px rgb(127, 124, 124);
  border-radius: 0.5rem;
}
.grovify{
  display: grid;
  width:300px;
  height: 250px;
  grid-template-columns: repeat(4,25%); 
  grid-template-rows: repeat(6,41.67px);
  grid-row-gap: 0;
  gap:0;
 
}
.ga{
  display: grid;
  width:300px;
  height: 250px;
  grid-template-columns: repeat(4,25%); 
  grid-template-rows: repeat(6,41.67px);
  grid-row-gap: 0;
  gap:0;
}
.rfsuny{
  height: 166.7px;
  width: 225px;
  grid-column: 1/3;
  grid-row: 1/4;
  border: solid 1px rgb(127, 124, 124);
  border-radius: 0.5rem;
}

.BLe{
  width: 75px;
  height: 83.34px;
  grid-column: 4/5;
  grid-row: 1/3;
  border: solid 1px rgb(127, 124, 124);border-radius: 0.5rem;
}

.adc{
  width: 75px;
  height: 83.34px;
  grid-column: 4/5;
  grid-row: 3/4;
  border: solid 1px rgb(127, 124, 124);
  border-radius: 0.5rem;
}

.ecg{
  width: 150px;
  height:41.67px ;
  grid-row: 5;
  grid-column: 1/3;
  border: solid 1px rgb(127, 124, 124);
  border-radius: 0.5rem;
}
  
.TI{
  width: 150px;
  height:41.67px ;
  grid-row: 5;
  grid-column: 3/5;
  border: solid 1px rgb(127, 124, 124);
  border-radius: 0.5rem;
}

.noteapp{
  display: grid;
  width:300px;
  height: 250px;
  grid-template-columns: repeat(4,25%); 
  grid-template-rows: repeat(6,41.67px);
  grid-row-gap: 0;
  gap:0;
}
.note1{
grid-column: 3/5;
grid-row: 1/2;
width: 150px;
height: 83.34px;
border: solid 1px rgb(127, 124, 124);
  border-radius: 0.5rem;
}
.note2{
  grid-column: 1/4;
  grid-row: 3/6;
  width: 225px;
  height: 125px;
  border: solid 1px rgb(127, 124, 124);
  border-radius: 0.5rem;
}

.note3{
  grid-column: 1/3;
  grid-row: 1/3;
  width: 150px;
height: 83.34px;
border: solid 1px rgb(127, 124, 124);
  border-radius: 0.5rem;
}

.note4{
  grid-row: 3/4;
  grid-column: 4/5;
  width: 75px;
  height: 83.34px;
  border: solid 1px rgb(127, 124, 124);
  border-radius: 0.5rem;
}
.note5{
  grid-row: 5/6;
  grid-column: 4/5;
  width: 75px;
  height: 41.67px;
  border: solid 1px rgb(127, 124, 124);
  border-radius: 0.5rem;
}

.parkinglot{
  display: grid;
  width:300px;
  height: 250px;
  grid-template-columns: repeat(4,25%); 
  grid-template-rows: repeat(6,41.67px);
  grid-row-gap: 0;
  gap:0;
  
}
.parking1{
  grid-column: 1/5;
  grid-row: 1/3;
  width: 300px;
  height: 125px;
  border: solid 1px rgb(127, 124, 124);
  border-radius: 0.5rem;
}

.parking2{
  grid-row: 4/6;
  grid-column: 1/2;
  width: 75px;
  height: 83.34px;
  border: solid 1px rgb(127, 124, 124);
  border-radius: 0.5rem;
}
.parking3{
  grid-row: 4/6;
  grid-column: 2/3;
  width: 75px;
  height: 83.34px;
  border: solid 1px rgb(127, 124, 124);
  border-radius: 0.5rem;
}
.parking4{
  grid-row: 4/6;
  grid-column: 3/5;
  width: 150px;
  height: 83.34px;
  border: solid 1px rgb(127, 124, 124);
  border-radius: 0.5rem;
}

.grovify-app{

margin-top: 10px;
font-size: 25px;
width: 300px;
grid-row: 6;
display: flex;
align-items: center;
justify-content: center;
font-weight: 700;
color: rgb(0, 0, 0);
}
.sub{
 
  font-size: 15px;
  font-style: italic;

}