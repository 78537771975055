.box{
    position: absolute;
    align-self:flex-end baseline;
    right:10%;
}

.items{
display: flex;
gap: 2rem;
font-size: larger;
color: rgb(237, 231, 231);
font-weight: 700;

}

.Resume{
    cursor: pointer;
}