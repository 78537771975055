.fname{
    position: absolute;
    color: rgb(224, 243, 243);
    font-family: var(--font-mono);
    font-size: 40px;
    font-weight: 600;
    left:15rem;
    cursor: pointer;
}

.lname{
    position: absolute;
    color: rgb(192, 212, 212);
    font-family: var(--font-mono);
    font-size: 20px;
    font-weight: 600;
    left:2rem;
}

.header-list{
    display: flex;
    width: 100%;
    height: 5rem;
    flex-direction: row;
    align-items: center;
    align-content: space-between;
    
    
}