.arch{
    height: 40rem;
}
.results{
    height: 30rem;
}

.app{
    height: 40rem;
}

.parking-main{
    color:white;
}
.parking-body{
    padding: 1rem 5rem;
}