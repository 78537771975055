.body {
    position: relative;
    height: 100vh !important;
    width: 100vw !important;
    background-color: rgb(27, 26, 26);
    margin: 0rem !important;
    overflow: hidden !important;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  }
 .common{
    
    width: 35vmin;
    height:45vmin;
    object-fit:cover;
    object-position: center;
    cursor: grab;

}
.image{
    position: relative;
    width: 35vmin;
    height:45vmin; 
    cursor: grab;
}
.image:hover .tooltip{
opacity: 1;

}
.double-click:hover{
cursor: pointer;
}
  #image-track{
    display: flex;
    gap: 3vmin;
    position: absolute;
    top:60vh;
    left:20vw;
    user-select: none;
    
  }
  .tooltip {
    color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    gap:0.5rem;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #3b3d3e7d;
    
  }
  .work{
    color: rgb(218, 212, 212);
    cursor: pointer;
  }
  .about{
    color: rgb(143, 143, 143);
    cursor: pointer;
    
  }
  .about:hover{
    color: rgb(218, 212, 212);
  }
  .nav-bar{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:3vmin;
    font-size: larger;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }
  .name{
    
    color: whitesmoke;
    font-size: 5vmin;
    font-weight: 700;
  }
  .header{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:2vmin;
    
    
  }
  .punchline{
    color: rgb(162, 160, 160);
    font-family:"Kaushan Script", cursive;
    font-weight: 500;
    font-size: 3vmin;
  }

  .under-construction{
    margin-left: 2vmin;

  }
  .caption{
    color:silver;
    font-size: smaller;
  }
  .grovify:hover .tooltiptext{
    visibility: visible;
  }
  .tooltiptext{
    visibility: hidden;
    background-color: white;
    cursor: pointer !important;
  }
  
  .tap{
    display: flex;
    align-items: center;
    justify-content: center;
    color: silver;
    cursor:grab;
  }