.agri-main{
    padding: 2rem 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:1rem;
    color: rgba(245, 245, 245, 0.769);
    
}
.tech-main{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}